import React from "react";
import Container from "react-bootstrap/Container";
import {
  ArrowRight,
  Facebook,
  Youtube,
  Instagram,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

import foodAssit from "../assets/img/foodassit.jpg";

const ExecutiveStrategy = () => {
  return (
    <Container className="py-5 mt-5">
      <Row className="pb-5">
        <Col md={7} sm={12} className="mb-5 pb-2">
          <h4 class="h4">Read this</h4>
          <h2 class="h1 pb-2 pb-md-0 mb-4 mb-md-5">Executive Summary</h2>
          <p>
            Achieving the Sustainable Development Goals (SDG) 2030 target set by
            the United Nations is very pertinent for developing Nations, most
            especially the SDG1- which is poverty alleviation. The approach to
            attaining this goal cannot be a one size fit all, individual
            countries must have a particular way to achieving these goals taking
            into cognizance the peculiarity of each nation. Nigeria as the most
            populated country in the continent should continue to seize
            opportunities it requires for its development.
          </p>
          <p>
            The investment required to achieve the Sustainable development goal
            and alleviating poverty in Nigeria is enormous and may be beyond the
            scope of Nigerian governments revenue to achieve without significant
            partnership from the international organization like the
            commonwealth, United Nations and other private organizations that
            assist Nations in the achievement of their goals. Nigeria government
            need to seek access to funds available for this purpose.
          </p>
          <p>
            If by 2030 Nigeria halve extreme poverty in the country from its
            current levels that would be a major achievement on the path to
            success.
          </p>
          <p>
            The challenge is enormous and requires the continuous intervention
            of international communities as well as good leadership and
            governance. The government also need to create awareness and
            insisting and encouraging education for all her citizens by
            providing affordable and efficient educational institutions in the
            country. Increasing the minimum wage to enable citizens sustain
            themselves.
          </p>
          <Link
            to="/strategy"
            className="btn btn-success shadow-success btn-lg fw-bolder mt-5 mb-5 flex"
          >
            Check out the strategy
          </Link>
          <a
            href="#"
            className="btn-link text-success btn-lg fw-bolder ms-3 mt-5 mb-5"
          >
            Follow Us <ArrowRight className="ms-3" />
          </a>
          <Facebook
            size={32}
            style={{ marginRight: "20px", color: "lightgray" }}
          />
          <Instagram
            size={32}
            style={{ marginRight: "20px", color: "lightgray" }}
          />
          <Youtube
            size={42}
            style={{ marginRight: "10px", color: "lightgray" }}
          />
        </Col>
        <Col>
          <img src={foodAssit} />
        </Col>
      </Row>
    </Container>
  );
};

export default ExecutiveStrategy;
