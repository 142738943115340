import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import siteHeaderLogo from "../assets/img/slides/logo.png";

const CustomNavbar = () => {
  const navigate = useNavigate();

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={siteHeaderLogo} width="150" alt="Get Help" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link as={Link} to="/aboutus">
              About Us
            </Nav.Link> */}
            <NavDropdown
              title="About Us"
              id="basic-nav-dropdown"
              className="text-white hover:text-white"
            >
              <NavDropdown.Item
                as={Link}
                to="/aboutus"
                className="text-success"
              >
                GetHelp in a brief
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/goals" className="text-success">
                Mission &amp; Vision
              </NavDropdown.Item>
              {/* <NavDropdown.Item
                as={Link}
                to="/benefits"
                className="text-success"
              >
                Goals &amp; Objectives
              </NavDropdown.Item> */}
              <NavDropdown.Item
                as={Link}
                to="/summary"
                className="text-success"
              >
                Executive Summary
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/strategy"
                className="text-success"
              >
                Strategy
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/services">
              What we do
            </Nav.Link>
            <Nav.Link href="#news">News &amp; Insights</Nav.Link>
            <NavDropdown
              title="Project Categories"
              id="basic-nav-dropdown"
              className="text-white hover:text-white"
            >
              <NavDropdown.Item
                as={Link}
                to="/benefits"
                className="text-success"
              >
                Critical Food Subsidy (CFS)
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/benefitchs"
                className="text-success"
              >
                Critical Health Subsidy (CHS)
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/benefitces"
                className="text-success"
              >
                Critical Education Subsidy (CES)
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>

          <Nav>
            <Nav.Link
              as={Link}
              to="/registration"
              variant="outline-success"
              className="me-3"
            >
              Get Help Today
            </Nav.Link>
            <Button
              className="btn-success fw-bold text-white"
              onClick={() => navigate("/contactus")}
              variant="success"
            >
              Contact Us
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
