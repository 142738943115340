import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const SectionFive = () => {
  return (
    <section className="container pb-5 mb-md-2 mb-xl-4">
      <div className="row pb-lg-2">
        <div className="col-md-5 mb-4 mb-md-0">
          <div
            className="card justify-content-center h-100 p-4 p-lg-5"
            style={{ backgroundColor: "#01774c" }}
          >
            <div className="p-2">
              <h3 className="display-4 text-white mb-1">350+</h3>
              <h2 className="text-light pb-5 mb-2">GetHelp Already Served</h2>
              <a
                href="#"
                className="d-inline-flex align-items-center rounded-3 text-decoration-none py-3 px-4"
                style={{ backgroundColor: "#3e4265" }}
              >
                <div className="pe-3">
                  <div className="fs-xs text-light text-uppercase opaciy-70 mb-2">
                    Reviewed on
                  </div>
                </div>
                <div className="ps-1">
                  <div className="text-nowrap mb-2">
                    <i className="bx bxs-star text-warning"></i>
                    <i className="bx bxs-star text-warning"></i>
                    <i className="bx bxs-star text-warning"></i>
                    <i className="bx bxs-star text-warning"></i>
                    <i className="bx bxs-star text-warning"></i>
                  </div>
                  <div className="text-light opacity-70">49 reviews</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="card border-0 shadow-sm p-4 p-xxl-5">
            <div className="d-flex justify-content-between pb-4 mb-2">
              <span className="btn btn-icon btn-success btn-lg shadow-success pe-none">
                <i className="bx bxs-quote-left"></i>
              </span>
            </div>

            <div className="swiper mx-0 mb-md-n2 mb-xxl-n3 swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
              <h3>Give a helping hand to everyone</h3>
              <p>
                GETHELP has grown into an emerging behemoth and its ranks have
                swollen owing to the impactfulness of its agenda through the
                marshalling of resources towards: provision of free
                healthcare/medical services, orphanage and educational
                scholarships and kitting of school children, social awareness
                seminars such as anti-drug use campaigns and other socially
                conscious endeavors too numerous to mention.
              </p>
              <p>
                We also embark on food distribution support to the
                underprivileged/indigent through a scheme.
              </p>
              <Link
                className="btn btn-link text-success btn-lg mt-3"
                to="/goals"
              >
                Learn More <ArrowRight className="ms-3" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFive;
