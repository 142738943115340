import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";

const GoalsAgenda = () => {
  return (
    <Container className="container pb-2 mb-md-1 mb-lg-1 mb-xl-1">
      <Row className="row pb-5 mt-xl-5 mb-md-2 mb-lg-4 mb-xl-5">
        <Col xs={6}>
          <h1 class="display-1 mt-xl-5 mb-0">Our Vision</h1>
        </Col>
        <Col xs={6}>
          <p className="pb-5 mt-xl-5 fs-xl" style={{ marginBottom: "-20px" }}>
            Change as much life, create a safe haven for the needy and spread
            positivity around the world.
          </p>
          <Link
            to="/contactus"
            className="btn-link btn-lg fw-bolder text-success"
          >
            Reach out today <ArrowRight />
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default GoalsAgenda;
