import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Facebook, Youtube, Instagram, Play } from "react-bootstrap-icons";

const NewsInsigtSection = () => (
  <section className="container text-center py-5 my-2 my-md-4 my-lg-5">
    <h2 className="h1 mb-4">Our Social Networks</h2>
    <p className="fs-lg text-muted pb-2 mb-5">
      Follow us and keep up to date with the freshest news!
    </p>
    <div className="swiper">
      <div className="swiper-wrapper">
        <Container>
          <Row>
            <Col xs={6} md={4}>
              <div className="swiper-slide col">
                <div className="position-relative text-center border-end mx-n1">
                  <a
                    href="#"
                    className="btn btn-icon btn-secondary btn-facebook btn-lg stretched-link"
                  >
                    <Facebook size={42} />
                  </a>
                  <div className="pt-4">
                    <h6 className="mb-1">Facebook</h6>
                    <p className="fs-sm text-muted mb-0">gethelp</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="col">
                <div className="position-relative text-center border-end mx-n1">
                  <a
                    href="#"
                    className="btn btn-icon btn-secondary btn-instagram btn-lg stretched-link"
                  >
                    <Instagram size={42} />
                  </a>
                  <div className="pt-4">
                    <h6 className="mb-1">Instagram</h6>
                    <p className="fs-sm text-muted mb-0">@gethelp</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="col">
                <div className="position-relative text-center border-end mx-n1">
                  <a
                    href="#"
                    className="btn btn-icon btn-secondary btn-youtube btn-lg stretched-link"
                  >
                    <Youtube size={42} />
                  </a>
                  <div className="pt-4">
                    <h6 className="mb-1">YouTube</h6>
                    <p className="fs-sm text-muted mb-0">gethelp</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </section>
);

export default NewsInsigtSection;
