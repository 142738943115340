import GetInTouch from "../components/GetInTouch";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import ServiceCustomHero from "../components/ServiceCustomHero";

const Services = () => (
  <>
    <ServiceCustomHero
      title="What We Do"
      description="We at GetHelp know the value and power of eduction and therefore poised to be bridge to valid education to our society. Education is only a dream to some people, great minds are waiting to be unlocked by the power of education. It is only through eduction that the barrier of illiteracy can be broken. We are at the frontline as usual seeking those who are in need of help, GetHelp seeks your support in this valid initiative as we make eduction a dream come through to the society. GetHelp ensures our recipients looking for help and donors get a satisfaction of their help and donations. Let’s make the world a better place"
    />

    <section className="container pt-5 mt-2 mt-md-4 mt-lg-5">
      <div className="row pt-xl-3">
        <div className="col-md-5 text-center text-md-start pb-5">
          <h1 className="mb-4">
            Developing Health care, Educational &amp; Food Support
          </h1>
          <p className="fs-lg pb-lg-3 mb-4">
            GETHELP, as an implementation subsidiary of GIVARS, share the same
            stakeholders and personnel as its parent body, which comprises of a
            network of friends, thought leaders and philanthropists, who are
            willing to apply time and resources for the common purpose of being
            change agents. The agenda of the promoters is vested on the enduring
            qualities of our African Heritage which is communal at its core. We
            advance these qualities by defending social welfare and inclusion
            for the most vulnerable in our society and also to promote peace and
            good character among our youths.
          </p>
          <Link
            to="/contactus"
            className="btn btn-success shadow-success btn-lg"
          >
            Contact Us Today
          </Link>
        </div>
        <div className="col-xl-6 col-md-7 offset-xl-1 pb-4 pb-sm-3 pb-lg-0 mb-4 mb-sm-5 mb-lg-0">
          <img
            src="https://silicon.createx.studio/assets/img/landing/software-company/about-img.jpg"
            className="rounded-3 shadow-sm"
            alt="Image"
          />
        </div>
      </div>
    </section>

    <Container className="mt-5 mb-5">
      <Row class="row justify-content-center pb-5">
        <Col xs lg="1"></Col>

        <Col>
          <h1 className="mb-4 mt-5 text-center">How we make impact</h1>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            Globally about 8.9% of the world population go to bed hungry, this
            number has steadily grew since 2014. It is expected that at the rate
            by the year 2030 the number will exceed 840 million. This singular
            reason coupled with the event of the Covid 19 has made it even more
            imperative for GetHelp to do something about it. As we take these
            little steps in putting smiles and ensuring a child’s belly is
            filled somewhere, you can be a part of this vision too, your
            donations and contributions will help us help the helpless. GetHelp
            is on the front line, ensuring that your help and our help is
            reaching the needy in our society.
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to="/contactus"
              className="btn btn-success shadow-success btn-lg"
            >
              Contact Us Today
            </Link>
          </div>
        </Col>
        <Col xs lg="1"></Col>
      </Row>
    </Container>

    <GetInTouch />
  </>
);

export default Services;
