import CustomCarousel from "../components/CustomCarousel";
import SectionOne from "../layouts/homepage/SectionOne";
import SectionTwo from "../layouts/homepage/SectionTwo";
import SectionThree from "../layouts/homepage/SectionThree";
import SectionFour from "../layouts/homepage/SectionFour";
import SectionFive from "../layouts/homepage/SectionFive";
import NewsInsigtSection from "../layouts/homepage/NewsInsightSection";

const Homepage = () => (
  <>
    <CustomCarousel />
    <SectionOne />
    <SectionTwo />
    <SectionThree />
    <SectionFour />
    <SectionFive />
    <NewsInsigtSection />
  </>
);

export default Homepage;
