import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { AspectRatio, Youtube, EmojiSmile, Play } from "react-bootstrap-icons";

const AboutUsSectionOne = () => (
  <>
    <section className="container mt-3 mb-5 pt-lg-5">
      <div className="swiper pt-3 swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
        <Container>
          <Row>
            <Col xs={6} md={4}>
              <div className="swiper-slide border-end-lg px-2">
                <div className="text-center">
                  <AspectRatio width="68" />
                  <h4 className="mb-2 pb-1">Trust</h4>
                  <p className="mx-auto">
                    GetHelp provides a trust support system for our
                    beneficiaries through our fund raising system
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="swiper-slide border-end-lg px-2">
                <div className="text-center">
                  <Play width="68" className="d-block mb-4 mx-auto" />
                  <h4 className="mb-2 pb-1">Hope</h4>
                  <p className="mx-auto">
                    We revive hope in people by meeting needs through our
                    programme
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="swiper-slide px-2">
                <div className="text-center">
                  <EmojiSmile width="58" className="d-block mb-4 mx-auto" />
                  <h4 className="mb-2 pb-1">Love</h4>
                  <p className="mx-auto">
                    We care for you, that's why we have our support system
                    running for this cause
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>

    <section className="container text-center pb-5 mt-n2 mt-md-0 mb-md-2 mb-lg-4">
      <div className="position-relative rounded-3 overflow-hidden">
        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center zindex-5">
          <a
            href="https://www.youtube.com/watch?v=zPo5ZaH6sW8"
            className="btn btn-video btn-icon btn-xl stretched-link bg-white"
          >
            <Play size={80} />
          </a>
        </div>
        <span className="position-absolute top-0 start-0 w-100 h-100 bg-black opacity-35"></span>
        <img
          src="https://silicon.createx.studio/assets/img/landing/digital-agency/cover.jpg"
          className="d-block w-100"
          alt="Cover image"
        />
      </div>
    </section>

    <Container>
      <Row className="row justify-content-center">
        <Col xs lg="1"></Col>
        <Col className="align-self-center">
          <h1 className="mb-4 text-center">Who we are</h1>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            GETHELP, as an implementation subsidiary of GIVARS, share the same
            stakeholders and personnel as its parent body, which comprises of a
            network of friends, thought leaders and philanthropists, who are
            willing to apply time and resources for the common purpose of being
            change agents.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            The agenda of the promoters is vested on the enduring qualities of
            our African Heritage which is communal at its core. We advance these
            qualities by defending social welfare and inclusion for the most
            vulnerable in our society and also to promote peace and good
            character among our youths.
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to="/contactus"
              className="btn btn-success shadow-success btn-lg"
            >
              Contact Us Today
            </Link>
          </div>
        </Col>
        <Col xs lg="1"></Col>
      </Row>
    </Container>
  </>
);

export default AboutUsSectionOne;
