import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/Container";
import family from "../../assets/img/foodie.jpg";

const GoalsAgendaDesc = () => {
  return (
    <section className="overflow-hidden pt-md-2 pt-lg-3 pb-3 pb-md-4 pb-xl-5">
      <Container>
        <div className="d-flex flex-column flex-md-row flex-md-nowrap align-items-start">
          <Col xs={12} xl={6} className="ps-0 pe-lg-4 mb-5 mb-md-0 ">
            <h3 className="mt-5">What are the objectives of GetHelp?</h3>
            <p>
              GETHELP has grown into an emerging behemoth and its ranks have
              swollen owing to the impactfulness of its agenda through the
              marshalling of resources towards: provision of free
              healthcare/medical services, orphanage and educational
              scholarships and kitting of school children, social awareness
              seminars such as anti-drug use campaigns and other socially
              conscious endeavors too numerous to mention. We also embark on
              food distribution support to the underprivileged/indigent through
              a scheme.
            </p>
          </Col>
          <img src={family} />
        </div>
      </Container>
    </section>
  );
};

export default GoalsAgendaDesc;
