import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Link } from "react-router-dom";
import foodAssit from "../assets/img/foodassit.jpg";

const Strategy = () => {
  return (
    <Container className="py-5 mt-5">
      <Row className="pb-5">
        <Col md={7} sm={12} className="mb-5 pb-2">
          <h2 class="h1 pb-2 pb-md-0 mb-4 mb-md-5">Strategy</h2>
          <p>
            Applications will be received by the agency in charge who will then
            look through the various applications and shortlist eligible
            applicants for the scheme. The qualified citizens will be given what
            we call Access card with which they can access the designated '
            centers’ in their localities and pay their bills at subsidized
            prices.
          </p>
        </Col>
        <Col>
          <img src={foodAssit} />
        </Col>
      </Row>
      <Row className="row justify-content-center mb-5 pb-5">
        <Col xs lg="1"></Col>
        <Col className="align-self-center">
          <h1 className="mb-4 text-center">
            How the Critical Subsidy Program Access Card Works
          </h1>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            After qualified applicants are registered, they will get a
            notification that their access card is being processed and in a week
            time they will be invited to go to the center nearest to their
            location to pick it up for activation.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            We have two types of access cards: Green and White.  The green
            access card is for 50% on all payment discount while the white is
            for 100% payment discount.  This discount applies to all schemes. 
            Customer service will be available in all states and local
            government area to assist in resolving issues.  The information on
            the access card is: Photo  Signature  Full names Home Address
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            Every center will have the access card readable machines and system
            data servers (online and offline). Using the access card is easy,
            after activation, go to a nearby location in your locality, shop for
            food items, pay your medical and school bills. go to the cash point
            and swipe your access card for payment.  There is an amount limit on
            each access card type.  For the green access card, you can only
            access N10, 000 payment worth at 50% discount. For the white access
            card, you can only access N10, 000 payment worth at 100% discount,
            (those living below poverty line). The system will be set up in the
            following way: There will be offices in 36 states and FCT so that no
            region is marginalized in this scheme. In turn, these state offices
            will coordinate the distribution of food items down to the
            grass-root by designating market days in major local government
            areas to enable everyone eligible have access to the food items.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            Besides the FG intervention for this program, we have outlined other
            alternative ways it can be self-funding.They include:
            <ol>
              <li>
                Donations from big establishments and corporation like Dangote,
                Banks, Coca-Cola, Food bank Agencies, Government and Food
                Emergency Agencies.
              </li>
              <li>
                Reinvestment through monthly fixed deposit with banks. 3. Direct
                large-scale farming
              </li>
              <li>Direct large-scale farming</li>
            </ol>
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            Liabilities: Taking into cognizance the liabilities common to such
            initiative we decided to take some proactive steps to enable the
            program lessen its burden and reduce responsibility in some key
            areas. In lieu of this, we will contract out or privatize the
            activities of the following:
            <ol>
              <li> The supplier of foods items</li>
              <li> The logistics companies</li>
              <li> The warehouses </li>
              <li> Food stores  5. Employment companies.</li> 
            </ol>
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            We also take into consideration the risks that are likely to occur
            in the course of executing this program. They include but not
            limited to:
            <ol>
              <li> Food theft (leakages)</li>
              <li> Expiration</li>
              <li> Storage </li>
              <li> Food shortage</li>
              <li> Fire out brake</li>
              <li> Mob attacks</li>
            </ol>
          </p>

          <div className="d-flex justify-content-center">
            <Link
              to="/contactus"
              className="btn btn-success shadow-success btn-lg"
            >
              Contact Us Today
            </Link>
          </div>
        </Col>
        <Col xs lg="1"></Col>
      </Row>
    </Container>
  );
};

export default Strategy;
