import React from "react";
import {
  ArrowRight,
  Facebook,
  Youtube,
  Instagram,
} from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Link } from "react-router-dom";

const BenefitsCes = () => {
  return (
    <Container className="py-5 mt-5 mb-md-2 mb-lg-5">
      <Row className="row justify-content-center mb-5 pb-5">
        <Col xs lg="1"></Col>
        <Col className="align-self-center">
          <h1 className="mb-4 text-center">Critical Education Subsidy (CES)</h1>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            The Educational system is fraught with a plethora of shortcomings
            ranging from lack of infrastructure, training materials, awareness,
            competent educators on one hand, to the age long bane of corruption
            and lack of commitment on the part of our administrators, being a
            predominant problem across board.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            The above, in addition to the assorted crises of infrastructural
            decay, neglect, waste of resources and sordid conditions of service,
            contribute to the deplorable state of the educational sector in
            Nigeria. The country currently has over 10 million children who are
            not in school. This is the highest in the world. Another 27 million
            children in school record abysmal performances. Millions of
            Nigerians are half-educated, and over 60 million – or 30% –
            are illiterate.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            In addition to the forgoing, many eligible young Nigerians can’t
            gain admission into public universities. At the same time
            prohibitive tuition fees, among other factors, are a barrier to the
            country’s private universities.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            Funding is the biggest problem confronting Nigeria’s education
            system. The percentage of the budget allocated to education annually
            is abysmally low. In 2018, only 7.04% was allocated to education.
            This is far below UNESCO’s recommended 15%-26%.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            Nigeria’s experience with the commercialization and neglect of
            government secondary and primary school levels has led to poorer
            education outcomes. Nor is privatization the answer: it’s only
            likely to widen the gap between the rich and the poor. It denies
            children affordable quality education, increase the rate of
            illiteracy and reduce academic performance at the tertiary level.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            It is common knowledge that the quality of a country’s social and
            economic circumstance is a direct offshoot of the quantity and
            quality of the educational manpower available to provide qualitative
            labor. This also drills down to the capacity of the populace to be
            self-sufficient.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            In contributing our quota to help ameliorate the problem, we propose
            a Federal Government intervention scheme tagged 'Critical Education
            Subsidy' (CES) set up to address the low literacy issue and
            educational deficit by providing funding, infrastructure, training
            and awareness.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            A structure will be developed that will be employed to implement and
            administer the resources to ensure it impacts those that need
            assistance the most. Details are treated in below.
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to="/contactus"
              className="btn btn-success shadow-success btn-lg"
            >
              Contact Us Today
            </Link>
          </div>
        </Col>
        <Col xs lg="1"></Col>
      </Row>
      <Row className="pb-5">
        <Col md={7} sm={12} className="mb-5 pb-2">
          <h4 class="h4">Benefits you need to know</h4>
          <h2 class="h1 pb-2 pb-md-0 mb-4 mb-md-5">
            Critical Education Subsidy (CES) Program
          </h2>
          <div class="list-group list-group-flush">
            <li class="list-group-item list-group-item-action fw-normal">
              Inclusiveness and Equity: It will encourage literacy for all, and
              consequently alleviate poverty. An example of a literate country
              is Singapore, a country with learned people also reduces social
              menace/ miscreants because the citizens will not be easily
              manipulated to perpetuate crimes.
            </li>
            <li class="list-group-item list-group-item-action text-white bg-success">
              Economic Development: A Country with literate citizens will enable
              talents development, people with special skills are able to
              discover and develop it, and this could bridge skill gap in the
              country especially in terms of Technology etc.
            </li>
            <li class="list-group-item list-group-item-action fw-normal">
              Youth empowerment and job creation: By setting up nationwide
              offices, youths will be actively engaged at various stages of the
              program for the overall success of the intervention.
            </li>
            <li class="list-group-item list-group-item-action fw-normal">
              Development of Human Capital
            </li>
            <li class="list-group-item list-group-item-action fw-normal">
              This is necessary for any country who wants to improve its
              Socio-economic development, having people with the right skill set
              to man different sectors of the economy with grow the nations GDP
            </li>
          </div>
          <Link
            to="/summary"
            className="btn btn-success shadow-success btn-lg fw-bolder mt-5 mb-5 flex"
          >
            Summary
          </Link>
          <a
            href="#"
            className="btn-link text-success btn-lg fw-bolder ms-3 mt-5 mb-5"
          >
            Follow Us <ArrowRight className="ms-3" />
          </a>
          <Facebook
            size={32}
            style={{ marginRight: "20px", color: "lightgray" }}
          />
          <Instagram
            size={32}
            style={{ marginRight: "20px", color: "lightgray" }}
          />
          <Youtube
            size={42}
            style={{ marginRight: "10px", color: "lightgray" }}
          />
        </Col>
        <Col>Image</Col>
      </Row>
    </Container>
  );
};

export default BenefitsCes;
