import {
  ArrowRight,
  FileEarmarkMedical,
  EggFried,
  HeartPulse,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const SectionTwo = () => (
  <section className="bg-secondary pb-md-2 pb-lg-5">
    <div
      className="d-none d-lg-block"
      style={{ marginTop: "-60px", paddingTop: "60px" }}
    ></div>
    <div className="container pb-4 pt-5">
      <h2 className="h1 text-center text-md-start mb-lg-4 pt-1 pt-md-4">
        What we do
      </h2>
      <div className="row align-items-center pb-5 mb-lg-2">
        <div className="col-md-8 text-center text-md-start">
          <p className="fs-lg text-muted mb-md-0">
            GETHELP, as an implementation subsidiary of GIVARS, share the same
            stakeholders and personnel as its parent body, which comprises of a
            network of friends, thought leaders and philanthropists, who are
            willing to apply time and resources for the common purpose of being
            change agents.
          </p>
        </div>
        <div className="col-md-4 d-flex justify-content-center justify-content-md-end">
          <Link to="/aboutus" className="btn btn-success btn-lg">
            Learn More Today
          </Link>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2">
        <div className="col py-4 my-2 my-sm-3">
          <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
            <div className="card-body pt-3">
              <div className="d-inline-block bg-success shadow-success rounded-3 position-absolute top-0 translate-middle-y p-3">
                <EggFried color="white" size={50} />
              </div>
              <h2 className="h4 d-inline-flex align-items-center">
                Critical Food Subsidy
                <i className="bx bx-right-arrow-circle text-success fs-3 ms-2"></i>
              </h2>
              <p className="fs-sm text-body mb-0">
                We are proposing a FG intervention scheme tagged &#39;Critical
                Food Subsidy&#39; (CFS) set up to address the stomach
                infrastructure of the people living below poverty line and those
                who earn low wages by providing food items for them at
                subsidized prices to meet their basic need.
              </p>
              <Link className="btn btn-link text-success" to="/benefit">
                Learn More <ArrowRight className="ms-3" />
              </Link>
            </div>
          </div>
        </div>

        <div className="col py-4 my-2 my-sm-3">
          <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
              <div className="d-inline-block bg-success shadow-success rounded-3 position-absolute top-0 translate-middle-y p-3">
                <HeartPulse color="white" size={50} />
              </div>
              <h2 className="h4 d-inline-flex align-items-center">
                Critical Health Subsidy
                <i className="bx bx-right-arrow-circle text-success fs-3 ms-2"></i>
              </h2>
              <p className="fs-sm text-body mb-0">
                In contributing our quota to help ameliorate the problem, we
                propose a Federal Government intervention scheme tagged
                &#39;Critical Health Subsidy&#39; (CHS) set up to address the
                shortcomings in the medical sector by providing funding,
                infrastructure, training and awareness.
              </p>
              <Link className="btn btn-link text-success" to="/benefitchs">
                Learn More <ArrowRight className="ms-3" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2">
        <div className="col py-4 my-2 my-sm-3">
          <div className="card card-hover h-100 border-0 shadow-sm text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
            <div className="card-body pt-3">
              <div className="d-inline-block bg-success shadow-success rounded-3 position-absolute top-0 translate-middle-y p-3">
                <FileEarmarkMedical color="white" size={50} />
              </div>
              <h2 className="h4 d-inline-flex align-items-center">
                Critical Education Subsidy
                <i className="bx bx-right-arrow-circle text-success fs-3 ms-2"></i>
              </h2>
              <p className="fs-sm text-body mb-0">
                In contributing our quota to help ameliorate the problem, we
                propose a Federal Government intervention scheme tagged
                &#39;Critical Education Subsidy&#39; (CED) set up to address the
                low literacy issue and educational deficit by providing funding,
                infrastructure, training and awareness.
              </p>
              <Link className="btn btn-link text-success" to="/benefitces">
                Learn More <ArrowRight className="ms-3" />
              </Link>
            </div>
          </div>
        </div>

        <div className="col py-4 my-2 my-sm-3">
          <div className="h-100 border-0 text-decoration-none pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
            <div className="card-body pt-3">
              <div className="d-inline-block bg-success shadow-success rounded-3 position-absolute top-0 translate-middle-y p-3">
                <HeartPulse color="white" size={50} />
              </div>
              <h2 className="h4 d-inline-flex align-items-center">
                Our Mission &amp; Objectives
                <i className="bx bx-right-arrow-circle text-success fs-3 ms-2"></i>
              </h2>
              <p className="fs-sm text-body mb-0">
                Our mission is to implement GIVARS programs designed to support
                our young men and women with access quality health care, reach
                out to the less privileged, take young children back to
                school,..
              </p>
              <Link className="btn btn-success btn-lg mt-4" to="/benefitchs">
                Learn More <ArrowRight className="ms-3" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default SectionTwo;
