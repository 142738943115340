import sideHelperImg from "../../assets/img/slides/generosity.jpeg";
import { Link } from "react-router-dom";

const SectionOne = () => (
  <section className="container pt-5 mt-2 mt-md-4 mt-lg-5">
    <div className="row pt-xl-3">
      <div className="col-md-5 text-center text-md-start pb-5">
        <h1 className="mb-4">Give a helping hand to everyone</h1>
        <p className="fs-lg pb-lg-3 mb-4">
          GIVARS consequently birthed GETHELP as its execution/implementation
          arm, saddled with the responsibility of implementing its goals and
          objectives in reaching out to its target audience.
        </p>
        {/* <p className="fs-lg pb-lg-3 mb-4">
          In addition, GetHelp continues to find new ways to push the narrative
          of creating a better society one community at a time, by direct
          intervention in individual causes. This is manifested in welfare
          programs within and outside its ranks.
        </p> */}
        <Link to="/aboutus" className="btn btn-success shadow-success btn-lg">
          More About Us
        </Link>
      </div>
      <div className="col-xl-6 col-md-7 offset-xl-1 pb-4 pb-sm-3 pb-lg-0 mb-4 mb-sm-5 mb-lg-0">
        <img src={sideHelperImg} className="rounded-3 shadow-sm" alt="Image" />
      </div>
    </div>
  </section>
);

export default SectionOne;
