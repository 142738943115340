import React from "react";
import GoalsAgenda from "../layouts/about-us/GoalsAgenda";
import GoalsAgendaDesc from "../layouts/goals/GoalsAgendaDesc";
import GoalsHeroHead from "../layouts/goals/GoalsHeroHead";
import HowItWorks from "../layouts/goals/HowItWorks";

const Goals = () => {
  return (
    <>
      <GoalsHeroHead
        title="Our Mission"
        description="Our mission is to implement GIVARS programs designed to support our young men and
women with access quality health care, reach out to the less privileged, take young
children back to school, save young adults from the dangers of drug abuse and promote
good character among young people so that we can have a better and safe society.
Mission Statement: Support those without a voice, shining a light on their stories and
individualities."
      />
      <GoalsAgenda />
      <GoalsAgendaDesc />
      <HowItWorks />
    </>
  );
};

export default Goals;
