const OnlineConsultation = () => {
  return (
    <div
      className="col-xl-6 col-lg-7 offset-xl-2"
      style={{ marginBottom: "-100px" }}
    >
      <div className="card border-light shadow-lg py-3 p-sm-4 p-md-5">
        <div className="bg-dark position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block"></div>
        <div className="card-body position-relative zindex-2">
          <h2 className="card-title pb-3 mb-4">Get Online Consultation</h2>
          <form className="row g-4 needs-validation" novalidate>
            <div className="col-12">
              <label htmlFor="fn" className="form-label fs-base">
                Full name
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="fn"
                required
              />
              <div className="invalid-feedback">
                Please enter your full name!
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="email" className="form-label fs-base">
                Email address
              </label>
              <input
                type="email"
                className="form-control form-control-lg"
                id="email"
                required
              />
              <div className="invalid-feedback">
                Please provid a valid email address!
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="date" className="form-label fs-base">
                Message Body
              </label>
              <textarea className="form-control form-control-lg" required />
              <div className="invalid-feedback">Enter a date!</div>
            </div>

            <div class="col-12 pt-2 pt-sm-3">
              <button type="submit" class="btn btn-lg btn-success w-100">
                Send A Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OnlineConsultation;
