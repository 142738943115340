import aboutUsImageOne from "../assets/img/slides/aboutus.jpeg";
import aboutUsImageTwo from "../assets/img/slides/aboutus2.jpeg";
import aboutUsImageThree from "../assets/img/slides/howitworks.jpeg";
import aboutUsImageFour from "../assets/img/slides/how2.jpeg";
import { House } from "react-bootstrap-icons";

import { Link } from "react-router-dom";

const ServiceCustomHero = ({ title, description }) => (
  <section className="position-relative pt-5">
    <div
      className="position-absolute top-0 start-0 w-100 bg-position-bottom-center bg-size-cover bg-repeat-0"
      style={{ backgroundImage: `url("assets/img/about/hero-bg.svg")` }}
    >
      <div className="d-lg-none" style={{ height: "960px" }}></div>
      <div className="d-none d-lg-block" style={{ height: "768px" }}></div>
    </div>

    <div className="container position-relative zindex-5 pt-5">
      <div className="row">
        <div className="col-lg-6">
          <nav
            className="pt-md-2 pt-lg-3 pb-4 pb-md-5 mb-xl-4"
            aria-label="breadcrumb"
          >
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">
                  <House className="bx bx-home-alt fs-lg me-1" />
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item text-success" aria-current="page">
                {title}
              </li>
            </ol>
          </nav>

          <h1 className="pb-2 pb-md-3">{title}</h1>
          <p
            className="fs-xl pb-4 mb-1 mb-md-2 mb-lg-3"
            style={{ maxWidth: "526px" }}
          >
            {description}
          </p>
          <Link
            to="/registration"
            className="btn btn-success shadow-success btn-lg"
          >
            Register Today
          </Link>
        </div>

        <div className="col-lg-6 mt-xl-3 pt-5 pt-lg-4">
          <div className="row row-cols-2 gx-3 gx-lg-4">
            <div className="col pt-lg-5 mt-lg-1">
              <img
                src={aboutUsImageOne}
                className="d-block rounded-3 mb-3 mb-lg-4"
                alt="Image"
              />
            </div>
            <div className="col">
              <img
                src={aboutUsImageThree}
                className="d-block rounded-3 mb-3 mb-lg-4"
                alt="Image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ServiceCustomHero;
