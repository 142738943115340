import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Registration = () => (
  <>
    <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
      <Row className="w-100 align-self-end pt-1 pt-md-4 pb-4 mt-5">
        <Col className="mb-5">
          <h1 className="text-center text-xl-start">Create Account</h1>
          <form className="needs-validation">
            <div className="row">
              <div className="col-sm-6">
                <div className="position-relative mb-4">
                  <label for="name" className="form-label fs-base">
                    Full name
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Fullname same with BVN"
                    className="form-control form-control-lg"
                    required
                  />
                  <div className="invalid-feedback position-absolute start-0 top-100">
                    Please enter your name!
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="position-relative mb-4">
                  <label htmlFor="email" className="form-label fs-base">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Email Address to receive notifications"
                    className="form-control form-control-lg"
                    required
                  />
                  <div className="invalid-feedback position-absolute start-0 top-100">
                    Please enter a valid email address!
                  </div>
                </div>
              </div>
              <div className="col-12 mb-4">
                <label htmlFor="password" className="form-label fs-base">
                  Password
                </label>
                <div className="password-toggle">
                  <input
                    type="password"
                    id="password"
                    className="form-control form-control-lg"
                    required
                  />
                  <label
                    className="password-toggle-btn"
                    aria-label="Show/hide password"
                  >
                    <input className="password-toggle-check" type="checkbox" />
                    <span className="password-toggle-indicator"></span>
                  </label>
                  <div className="invalid-feedback position-absolute start-0 top-100">
                    Please enter a password!
                  </div>
                </div>
              </div>
              <div className="col-12 mb-4">
                <label
                  htmlFor="password-confirm"
                  className="form-label fs-base"
                >
                  Confirm password
                </label>
                <div className="password-toggle">
                  <input
                    type="password"
                    id="password-confirm"
                    className="form-control form-control-lg"
                    required
                  />
                  <label
                    className="password-toggle-btn"
                    aria-label="Show/hide password"
                  >
                    <input className="password-toggle-check" type="checkbox" />
                    <span className="password-toggle-indicator"></span>
                  </label>
                  <div className="invalid-feedback position-absolute start-0 top-100">
                    Please enter a password!
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="terms"
                  className="form-check-input"
                />
                <label htmlFor="terms" className="form-check-label fs-base">
                  I agree to{" "}
                  <a href="#" className="text-success">
                    Terms &amp; Conditions
                  </a>
                </label>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-success shadow-success btn-lg w-100"
            >
              Sign up
            </button>
          </form>
        </Col>
        <Col xs={6} md={4}>
          sdfsdf
        </Col>
      </Row>
    </div>
  </>
);

export default Registration;
