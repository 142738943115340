import { Person, Mailbox, Activity, Play } from "react-bootstrap-icons";

const SectionFour = () => {
  return (
    <section className="container py-5 my-2 my-md-4 my-lg-5">
      <h2 className="h1 text-center pt-1 pt-xl-2 mb-4">Why Choose Us?</h2>
      <p className="fs-lg text-muted text-center pb-4 mb-2 mb-lg-3">
        Change as much life, create a safe haven for the needy and spread
        positivity around the world.
      </p>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 pb-xl-3">
        <div className="col text-center border-end">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <Person color="success" size={42} />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">
            Register with your profile details
          </h3>
        </div>
        <div className="col text-center border-end">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <Mailbox color="success" size={42} />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">
            Wait for email confirmation &amp; completion
          </h3>
        </div>
        <div className="col text-center border-end">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <Activity color="success" size={42} />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">
            Activate your account &amp; check eligibility
          </h3>
        </div>
        <div className="col text-center">
          <div className="card d-table border-0 rounded-circle shadow-sm p-3 mx-auto mb-4">
            <Play size={42} />
          </div>
          <h3 className="h5 mb-2 mb-lg-0">
            Wait for background process completion
          </h3>
        </div>
      </div>
    </section>
  );
};

export default SectionFour;
