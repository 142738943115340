import React from "react";
import {
  ArrowRight,
  Facebook,
  Youtube,
  Instagram,
} from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Link } from "react-router-dom";

const BenefitsChs = () => {
  return (
    <Container className="py-5 mt-5 mb-md-2 mb-lg-5">
      <Row className="row justify-content-center mb-5 pb-5">
        <Col xs lg="1"></Col>
        <Col className="align-self-center">
          <h1 className="mb-4 text-center">Critical Health Subsidy (CHS)</h1>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            Healthcare in Nigeria is in an appalling state, to say the least, as
            a result of a combination of factors that range from lack of
            infrastructure and equipment, access to healthcare, lack of health
            insurance, outdated medical expertise/practice, motivation of
            healthcare workers, access to medical supplies on one hand, to the
            notorious issues of corruption and lack of commitment of the
            administrators, on the other hand.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            Health indicators in Nigeria are some of the worst in Africa. The
            country has one of the fastest growing populations globally. With
            5.5 live births per woman and a population growth rate of 3.2
            percent annually, It is estimated to reach 440 million people by
            2050. With its rapidly growing population and development
            challenges, the country drags down the socioeconomic indicators for
            the entire African continent.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            Healthcare infrastructure in Nigeria is still underdeveloped and
            lacks modern medical facilities. Medical professionals are in short
            supply, with only about 35,000 doctors despite needing 237,000,
            according to WHO figures, partially due to massive migration of
            healthcare workers overseas. As a result, Nigeria loses at least
            $1.5 billion every year to medical tourism, according to the
            Nigerian Medical Association (NMA).  Of this figure, India accounts
            for more than half.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            The poor and indigent, being the most affected in light of their
            economic circumstance; informs our decision to assist by providing
            funding, medical resources and training. In contributing our quota
            to help ameliorate the problem, we propose a Federal Government
            intervention scheme tagged 'Critical Health Subsidy' (CHS) set up to
            address the shortcomings in the medical sector by providing funding,
            infrastructure, training and awareness.
          </p>

          <div className="d-flex justify-content-center">
            <Link
              to="/contactus"
              className="btn btn-success shadow-success btn-lg"
            >
              Contact Us Today
            </Link>
          </div>
        </Col>
        <Col xs lg="1"></Col>
      </Row>
      <Row className="pb-5">
        <Col>Image</Col>
        <Col md={7} sm={12} className="mb-5 pb-2">
          <h4 class="h4">Benefits you need to know</h4>
          <h2 class="h1 pb-2 pb-md-0 mb-4 mb-md-5">
            Critical Health Subsidy (CHS) Program
          </h2>
          <div class="list-group list-group-flush">
            <li class="list-group-item list-group-item-action fw-normal">
              it protects you from unexpected high medical costs
            </li>
            <li class="list-group-item list-group-item-action text-white bg-success">
              You pay less for health care
            </li>
            <li class="list-group-item list-group-item-action fw-normal">
              You get free preventive care, like vaccines, screenings, and some
              check-ups
            </li>
            <li class="list-group-item list-group-item-action fw-normal">
              you don’t have to pay for some treatments
            </li>
            <li class="list-group-item list-group-item-action fw-normal">
              An image building initiative for the government.
            </li>
            <li class="list-group-item list-group-item-action disable fw-normal">
              it guarantees equitable access to basic healthcare services
            </li>
            <li class="list-group-item list-group-item-action disable fw-normal">
              No one plans to get sick or hurt, but most people need medical
              care at some point. Health (CHS) Subsidizes these costs and offers
              many other important benefits.
            </li>
          </div>
          <Link
            to="/benefitces"
            className="btn btn-success shadow-success btn-lg fw-bolder mt-5 mb-5 flex"
          >
            Learn More
          </Link>
          <a
            href="#"
            className="btn-link text-success btn-lg fw-bolder ms-3 mt-5 mb-5"
          >
            Follow Us <ArrowRight className="ms-3" />
          </a>
          <Facebook
            size={32}
            style={{ marginRight: "20px", color: "lightgray" }}
          />
          <Instagram
            size={32}
            style={{ marginRight: "20px", color: "lightgray" }}
          />
          <Youtube
            size={42}
            style={{ marginRight: "10px", color: "lightgray" }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default BenefitsChs;
