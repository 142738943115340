import React from "react";
import BenefitsCes from "../layouts/goals/BenefitsCes";
import BenefitsCfs from "../layouts/goals/BenefitsCfs";
import BenefitsChs from "../layouts/goals/BenefitsChs";

const Benefits = () => {
  return (
    <>
      <BenefitsCfs />
    </>
  );
};

export default Benefits;
