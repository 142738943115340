import Carousel from "react-bootstrap/Carousel";
import slideOne from "../assets/img/slides/slide-1.jpeg";
import slideTwo from "../assets/img/slides/slide-2.jpeg";
import slideThree from "../assets/img/slides/slide-3.jpeg";

const CustomCarousel = () => {
  return (
    <Carousel fade>
      <Carousel.Item style={{ height: "600px", overflow: "hidden" }}>
        <img className="d-block w-100" src={slideTwo} alt="First slide" />
        <Carousel.Caption>
          <h1 className="text-right text-white">
            Be a blessing while you live
          </h1>
          <p>
            These Bible verses encourage helping others, <br />
            because if life has given you blessings, <br />
            it's important to share those with your community.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ height: "600px", overflow: "hidden" }}>
        <img className="d-block w-100" src={slideThree} alt="Second slide" />

        <Carousel.Caption>
          <h1 className="text-white">Help a dream come alive</h1>
          <p>
            We make a living by what we get, we make a life by what we give
            after all, <br />
            no one is meant to go through life alone.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ height: "600px", overflow: "hidden" }}>
        <img className="d-block w-100" src={slideOne} alt="Second slide" />

        <Carousel.Caption>
          <h1 className="text-white">Everyone needs a little help </h1>

          <p>
            Making a difference to a campaign, to a group of people, <br />
            or to a single person, is what makes our efforts worthwhile.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CustomCarousel;
