import backgroundImg from "../../assets/img/slides/slide-3.jpeg";
import siteFooterLogo from "../../assets/img/slides/logo.png";
import { Link } from "react-router-dom";

const SectionThree = () => (
  <section className="bg-secondary pt-1">
    <div className="container">
      <h2 className="h1 text-center text-md-start mb-lg-4">Get Help Today</h2>
      <div className="row align-items-center pb-5">
        <div className="col-md-8 col-lg-7 col-xl-6 text-center text-md-start">
          <p className="fs-lg text-muted mb-md-0">
            We care for you, that's why we have our support system running for
            this cause. Harnesses the power of social networks and the internet
            to give people the means to raise funds and help
          </p>
        </div>
        <div className="col-md-4 col-lg-5 col-xl-6 d-flex justify-content-center justify-content-md-end">
          <a href="#" className="btn btn-success btn-lg font-bold">
            Register Now
          </a>
        </div>
      </div>
    </div>

    <div className="position-relative py-lg-4 py-xl-5">
      <div className="swiper-tabs position-absolute top-0 start-0 w-100 h-100">
        <div
          id="image-1"
          className="jarallax position-absolute top-0 start-0 w-100 h-100 swiper-tab active"
        >
          <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-35"></span>
          <div
            className="jarallax-img"
            style={{
              backgroundImage: `url(${backgroundImg})`,
            }}
          ></div>
        </div>
      </div>
      <div className="container position-relative zindex-5 py-5">
        <div className="row py-2 py-md-3">
          <div className="col-xl-5 col-lg-7 col-md-9">
            <div className="d-flex justify-content-center justify-content-md-start pb-3 mb-3"></div>

            <div className="card bg-white shadow-sm p-3">
              <div className="card-body">
                <div className="swiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <img
                        src={siteFooterLogo}
                        className="d-block mb-3"
                        width="102"
                        alt="Logo"
                      />
                      <h3 className="mb-2">GetHelp Workings</h3>
                      <p className="fs-sm text-muted border-bottom pb-3 mb-3">
                        How GetHelp works is so simple
                      </p>
                      <p className="pb-2 pb-lg-3 mb-3">
                        After qualified applicants are registered, they will get
                        a notification that their access card is being processed
                        and in a week time they will be invited to go to the
                        center nearest to their location to pick it up for
                        activation. We have two types of access cards: Green and
                        White. The green access card is for 50% on all payment
                        discount while the white is for 100% payment discount...
                      </p>
                      <Link to="/strategy" className="btn btn-success">
                        How it works
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dark-mode pt-4 mt-3">
              <div
                id="case-study-pagination"
                className="swiper-pagination position-relative bottom-0"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default SectionThree;
