import CustomHeroHead from "../components/CustomHeroHead";
import AboutUsSectionOne from "../layouts/about-us/AboutUsSectionOne";
import NewsInsigtSection from "../layouts/homepage/NewsInsightSection";

const AboutUs = () => (
  <>
    <CustomHeroHead
      title="About Us"
      description="GIVARS consequently birthed GETHELP as its execution/implementation arm,
saddled with the responsibility of implementing its goals and objectives in reaching out
to its target audience. In addition, GetHelp continues to find new ways to push the
narrative of creating a better society one community at a time, by direct intervention in
individual causes. This is manifested in welfare programs within and outside its ranks."
    />
    <AboutUsSectionOne />
    <NewsInsigtSection />
  </>
);

export default AboutUs;
