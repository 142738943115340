import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import siteFooterLogo from "../assets/img/slides/logo.png";

const Footer = () => (
  <footer className="footer dark-mode bg-dark pt-5 pb-4 pb-lg-5">
    <div className="container pt-lg-4">
      <div className="row pb-5">
        <div className="col-lg-4 col-md-6">
          <div className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
            <img src={siteFooterLogo} width="200" alt="Get Help" />
          </div>
          <p className="fs-sm text-light opacity-70 pb-lg-3 mb-4">
            We make it incredibly easy to raise money online for the things that
            matter to you most. In just a few minutes, you'll be able to
            personalize your fundraising campaign and share it with the people
            in your life and the public
          </p>
          <form className="needs-validation">
            <label htmlFor="subscr-email" className="form-label">
              Subscribe to our newsletter
            </label>
            <div className="input-group">
              <input
                type="email"
                id="subscr-email"
                className="form-control rounded-start ps-5"
                placeholder="Your email"
                required
              />
              <i className="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
              <div className="invalid-tooltip position-absolute top-100 start-0">
                Please provide a valid email address.
              </div>
              <button type="submit" className="btn btn-success">
                Subscribe
              </button>
            </div>
          </form>
        </div>
        <div className="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0">
          <div id="footer-links" className="row">
            <div className="col-lg-4">
              <h6 className="mb-2">
                <a
                  href="#useful-links"
                  className="d-block text-dark dropdown-toggle d-lg-none py-2"
                  data-bs-toggle="collapse"
                >
                  Useful Links
                </a>
              </h6>
              <div
                id="useful-links"
                className="collapse d-lg-block"
                data-bs-parent="#footer-links"
              >
                <ul className="nav flex-column pb-lg-1 mb-lg-3">
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      as={Link}
                      to="/"
                      className="nav-link d-inline-block px-0 pt-1 pb-2"
                    >
                      Home
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      as={Link}
                      to="/aboutus"
                      className="nav-link d-inline-block px-0 pt-1 pb-2"
                    >
                      About Us
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      as={Link}
                      to="/services"
                      className="nav-link d-inline-block px-0 pt-1 pb-2"
                    >
                      What we do
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      href="/"
                      className="nav-link d-inline-block px-0 pt-1 pb-2"
                    >
                      News &amp; Insights
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      as={Link}
                      to="/registration"
                      className="nav-link d-inline-block px-0 pt-1 pb-2"
                    >
                      Get Help
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      as={Link}
                      to="/contactus"
                      className="nav-link d-inline-block px-0 pt-1 pb-2"
                    >
                      Contact Us
                    </Nav.Link>
                  </Nav.Item>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-3">
              <h6 className="mb-2">
                <a
                  href="#social-links"
                  className="d-block text-dark dropdown-toggle d-lg-none py-2"
                  data-bs-toggle="collapse"
                >
                  Socials
                </a>
              </h6>
              <div
                id="social-links"
                className="collapse d-lg-block"
                data-bs-parent="#footer-links"
              >
                <ul className="nav flex-column mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a
                      href="#"
                      className="nav-link d-inline-block px-0 pt-1 pb-2"
                    >
                      Facebook
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#"
                      className="nav-link d-inline-block px-0 pt-1 pb-2"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#"
                      className="nav-link d-inline-block px-0 pt-1 pb-2"
                    >
                      Twitter
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#"
                      className="nav-link d-inline-block px-0 pt-1 pb-2"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 pt-2 pt-lg-0">
              <h6 className="mb-2">Contact Us</h6>
              <a
                href="mailto:email@example.com"
                className="fw-medium text-success"
              >
                info@gethelp.ng
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="nav d-block fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0">
        <span className="text-light opacity-50">© All rights reserved.</span>
      </p>
    </div>
  </footer>
);

export default Footer;
