import CustomNavbar from "./layouts/CustomNavbar";
import "./assets/css/theme.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./layouts/Footer";
import Homepage from "./pages/Homepage";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";
import Registration from "./pages/Registration";
import Goals from "./pages/Goals";
import Benefits from "./pages/Benefits";
import BenefitsChs from "./layouts/goals/BenefitsChs";
import BenefitsCes from "./layouts/goals/BenefitsCes";
import ExecutiveStrategy from "./pages/ExecutiveStrategy";
import Strategy from "./pages/Strategy";
import ScrollToTop from "./pages/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <main className="page-wrapper">
          <CustomNavbar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/goals" element={<Goals />} />
            <Route path="/benefits" element={<Benefits />} />
            <Route path="/benefitchs" element={<BenefitsChs />} />
            <Route path="/benefitces" element={<BenefitsCes />} />
            <Route path="/summary" element={<ExecutiveStrategy />} />
            <Route path="/strategy" element={<Strategy />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/registration" element={<Registration />} />
          </Routes>
          <Footer />
        </main>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
