import React from "react";
import {
  ArrowRight,
  Facebook,
  Youtube,
  Instagram,
} from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Link } from "react-router-dom";

const BenefitsCfs = () => {
  return (
    <Container className="py-5 mt-5 mb-md-2 mb-lg-5 mt-lg-3">
      <Row className="row justify-content-center mb-5 pb-5">
        <Col xs lg="1"></Col>
        <Col className="align-self-center">
          <h1 className="mb-4 text-center">Critical Food Subsidy (CFS)</h1>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            The United Nations has set target to achieve her 17-item global
            Sustainable Development by year end 2030. Although Sustainable
            Development has gained traction and world-wide publicity a lot of
            countries globally still do not have the where-withal and the know-
            how that is needed to achieve this goal of which the most prominent
            is poverty alleviation in Africa (SDG 1).
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            The unimaginable extent of poverty in Africa cannot be
            over-emphasized, Nigeria, the most populous Nation in the continent
            according to the National Bureau of Statistics (NBS) in a report
            based on data of Nigerian living standards survey about poverty and
            inequality from September 2018 to October 2019, said over 40 percent
            of Nigeria’s total population which is about eighty-three (83)
            million people live below its poverty line of N137,430.00 per annum
            ( One Hundred and Thirty-Seven Thousand Four Hundred and Thirty
            Naira Only)an equivalent of $381.20 United States Dollars as at
            January 2021.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            The Nigerian living Standard Survey is the official survey tool used
            in measuring the extent of poverty in the country and it is also
            used to estimate socio-economic indicators one of which is the
            benchmark of the Sustainable Development goals by the United
            Nations. (NBS 2020 report).
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            Organizations like the International Futures and World Data Lab had
            forecasted decline in poverty level globally, however it also
            projected increase in poverty level in the next 10 years in Africa.
            The major driver of poverty in the continent is the increase in
            population growth, according to projections the world population is
            forecasted to increase from about 7.7 billion people in 2019 to
            about 9.5 billion people in 2045, this growth will come
            significantly from Africa and it is also expected that of this
            population 550 million people will be living in poverty by 2030.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            There is urgent need to eliminate extreme poverty this will be the
            basis for development strategy for Africa especially Nigeria,
            achieving this involves all the governments, developmental agencies,
            non-governmental organizations, civil society need to design
            policies and advocate strategies that will eliminate poverty as
            their main focus.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            In Nigeria today a large section of the population living below the
            poverty line is attributed to the high level of unemployment and
            rising cost of living in the country.
          </p>
          <p className="fs-lg pb-lg-3 mb-4 text-center">
            The most basic need of the human race has become increasingly
            difficult to meet hence, to tackle Nigeria's numerous challenges we
            need to start from the basics.
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to="/contactus"
              className="btn btn-success shadow-success btn-lg"
            >
              Contact Us Today
            </Link>
          </div>
        </Col>
        <Col xs lg="1"></Col>
      </Row>
      <Row className="pt-5 pb-5">
        <Col md={7} sm={12} className="mb-5 pb-2">
          <h4 class="h4">Benefits you need to know</h4>
          <h2 class="h1 pb-2 pb-md-0 mb-4 mb-md-5">
            Critical Food Subsidy (CFS) Program
          </h2>
          <div class="list-group list-group-flush">
            <li class="list-group-item list-group-item-action fw-normal">
              Meeting the wellbeing of the people by providing food on their
              table at subsidized prices despite the hike in food items in the
              conventional market place.
            </li>
            <li class="list-group-item list-group-item-action text-white bg-success shadow-success">
              Engaging the agricultural value chain to ensure food sufficiency
              of the program i.e. acting as off takers of agricultural produce
              from the farmers and setting aside for sale to the people at
              reduced prices.
            </li>
            <li class="list-group-item list-group-item-action fw-normal">
              Youth empowerment and job creation: By setting up nationwide
              offices, youths will be actively engaged at various stages of the
              program for the overall success of the intervention.
            </li>
            <li class="list-group-item list-group-item-action fw-normal">
              Over time, a spiral effect will result in the reduction of food
              items in the conventional market place as those who can't sell off
              their high-priced produce would either see it go to waste or
              conform to our initiative.
            </li>
            <li class="list-group-item list-group-item-action fw-normal">
              An image building initiative for the government.
            </li>
            <li class="list-group-item list-group-item-action disable fw-normal">
              A healthy nation is a wealthy nation: This project will reduce
              cases of malnourishment leading to severe health conditions,
              enable a more productive society as only one who has taken care of
              the stomach can be relied upon for productive output in the
              workplace.
            </li>
          </div>
          <Link
            to="/benefitchs"
            className="btn btn-success shadow-success btn-lg fw-bolder mt-5 mb-5 flex"
          >
            Learn More
          </Link>
          <a
            href="#"
            className="btn-link text-success btn-lg fw-bolder ms-3 mt-5 mb-5"
          >
            Follow Us <ArrowRight className="ms-3" />
          </a>
          <Facebook
            size={32}
            style={{ marginRight: "20px", color: "lightgray" }}
          />
          <Instagram
            size={32}
            style={{ marginRight: "20px", color: "lightgray" }}
          />
          <Youtube
            size={42}
            style={{ marginRight: "10px", color: "lightgray" }}
          />
        </Col>
        <Col>Image Here</Col>
      </Row>
    </Container>
  );
};

export default BenefitsCfs;
