import React from "react";
import {
  ArrowRight,
  Facebook,
  Youtube,
  Instagram,
} from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

const HowItWorks = () => {
  return (
    <Container className="py-4 mb-md-2 mb-lg-5 mt-lg-3">
      <h4 class="h4">How It Works?</h4>
      <h2 class="h1 pb-2 pb-md-0 mb-4 mb-md-5">
        Scheme, Structure &amp; Structure
      </h2>
      <p className="col-md-7 col-sm-12 mb-5 pb-2">
        Applications will be received by the agency in charge who will then look
        through the various applications and shortlist eligible applicants for
        the scheme. The qualified Applicant will be given an access CARD with
        which they can access the designated &#39;centers’ in their localities
        at subsidized prices.
      </p>
      <div class="steps steps-sm steps-horizontal-md pb-2 pb-sm-3">
        <div class="step">
          <div class="step-number bg-warning">
            <div class="step-number-inner">1</div>
          </div>
          <div class="step-body">
            <h3 class="h4 mb-3">Critical Food Subsidy (CFS)</h3>
            <p class="mb-0">
              We are proposing a FG intervention scheme tagged &#39;Critical
              Food Subsidy&#39; (CFS) set up to address the stomach
              infrastructure of the people living below poverty line and those
              who earn low wages by providing food items for them at subsidized
              prices to meet their basic need.
            </p>
            <Link
              to="/benefits"
              className="btn-link btn btn-lg text-success fw-bolder mt-5 mb-5"
            >
              Learn More <ArrowRight className="ms-3" />
            </Link>
          </div>
        </div>
        <div class="step">
          <div class="step-number bg-info">
            <div class="step-number-inner">2</div>
          </div>
          <div class="step-body">
            <h3 class="h4 mb-3">Critical Health Subsidy (CHS)</h3>
            <p class="mb-0">
              In contributing our quota to help ameliorate the problem, we
              propose a Federal Government intervention scheme tagged
              &#39;Critical Health Subsidy&#39; (CHS) set up to address the
              shortcomings in the medical sector by providing funding,
              infrastructure, training and awareness. The implementation
              structure and process are treated in details in this body of work.
            </p>
            <Link
              to="/benefitchs"
              className="btn-link btn btn-lg text-success fw-bolder mt-5 mb-5"
            >
              Learn More <ArrowRight className="ms-3" />
            </Link>
          </div>
        </div>
        {/* <div class="step">
          <div class="step-number">
            <div class="step-number-inner">3</div>
          </div>
          <div class="step-body">
            <h3 class="h4 mb-3">Creating low-fidelity prototypes</h3>
            <p class="mb-0">
              Pellentesque rhoncus viverra vestibulum, purus purus quisque
              quisque vestibulum facilisis dictumst consequat enim libero.
            </p>
          </div>
        </div> */}
        <div class="step">
          <div class="step-number bg-success">
            <div class="step-number-inner">3</div>
          </div>
          <div class="step-body">
            <h3 class="h4 mb-3">Critical Education Subsidy (CES)</h3>
            <p class="mb-0">
              In contributing our quota to help ameliorate the problem, we
              propose a Federal Government intervention scheme tagged
              &#39;Critical Education Subsidy&#39; (CED) set up to address the
              low literacy issue and educational deficit by providing funding,
              infrastructure, training and awareness.
            </p>
            <Link
              to="/benefitces"
              className="btn-link btn btn-lg text-success fw-bolder mt-5 mb-5"
            >
              Learn More <ArrowRight className="ms-3" />
            </Link>
          </div>
        </div>
      </div>

      <a href="#" className="btn btn-success btn-lg fw-bolder mt-5 mb-5">
        Follow Us <ArrowRight className="ms-3 me-3" />
      </a>
      <Facebook
        className="ms-3"
        size={32}
        style={{ marginRight: "20px", color: "lightgray" }}
      />
      <Instagram
        size={32}
        style={{ marginRight: "20px", color: "lightgray" }}
      />
      <Youtube size={42} style={{ marginRight: "10px", color: "lightgray" }} />
    </Container>
  );
};

export default HowItWorks;
