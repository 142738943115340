import Accordion from "react-bootstrap/Accordion";

const Faqs = () => (
  <section class="container" style={{ marginTop: "100px" }}>
    <div class=" rounded-3 py-5 px-3 px-sm-4 px-md-0">
      <h2 class="text-center pt-md-1 pt-lg-3 pt-xl-4 pb-4 mt-xl-1 mb-2">
        Frequently Asked Questions
      </h2>
      <div class="row justify-content-center pb-lg-4 pb-xl-5">
        <div class="col-xl-8 col-lg-9 col-md-10 pb-md-2">
          <Accordion class="accordion">
            <Accordion.Item
              eventKey="0"
              class="accordion-item border-0 rounded-5 shadow-sm mb-3"
              style={{ marginBottom: "20px" }}
            >
              <Accordion.Header class="accordion-header">
                How does GetHelp works
              </Accordion.Header>
              <Accordion.Body class="accordion-body fs-sm pt-4">
                <div className=" text-sm">
                  <p>
                    Nunc duis id aenean gravida tincidunt eu, tempor
                    ullamcorper. Viverra aliquam arcu, viverra et, cursus.
                    Aliquet pretium cursus adipiscing gravida et consequat
                    lobortis arcu velit. Nibh pharetra fermentum duis accumsan
                    lectus non. Massa cursus molestie lorem scelerisque
                    pellentesque. Nisi, enim, arcu purus gravida adipiscing
                    euismod montes, duis egestas. Vehicula eu etiam quam
                    tristique tincidunt suspendisse ut consequat.
                  </p>
                  <p>
                    Ornare senectus fusce dignissim ut. Integer consequat in eu
                    tortor, faucibus et lacinia posuere. Turpis sit viverra
                    lorem suspendisse lacus aliquam auctor vulputate. Quis
                    egestas aliquam nunc purus lacus, elit leo elit facilisi.
                    Dignissim amet adipiscing massa integer.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="1"
              className="accordion-item border-0 rounded-5 shadow-sm mb-3"
            >
              <Accordion.Header>Is GetHelp an NGO</Accordion.Header>
              <Accordion.Body class="accordion-body fs-sm pt-4">
                <div className=" text-sm">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  </section>
);

export default Faqs;
