import Container from "react-bootstrap/Container";

const GetInTouch = () => (
  <Container>
    <div
      className="bg-secondary rounded-3 py-5 px-3 px-sm-4 px-lg-0"
      style={{ marginBottom: "-100px" }}
    >
      <div className="row align-items-center pt-1 pb-2 py-lg-4">
        <div className="col-xl-4 col-lg-5 col-md-6 offset-lg-1 mb-4 pb-3 mb-md-0 pb-md-0">
          <h2 className="h1 mb-lg-4">Get in Touch</h2>
          <p className="pb-2 pb-md-4 pb-lg-5">
            Do you need help for education, food distribution, clothing, health
            care, ask questions and reach out to us today.
          </p>
          <h3 className="mb-lg-4">Contact Info</h3>
          <ul className="list-unstyled pb-3 pb-md-4 pb-lg-5 mb-2">
            <li className="mb-2">
              <a
                href="tel:4065550120"
                className="nav-link d-flex align-items-center p-0"
              >
                <i className="bx bx-phone-call fs-xl text-primary me-2"></i>
                (406) 555-0120
              </a>
            </li>
            <li className="mb-2">
              <a
                href="mailto:example@email.com"
                className="nav-link d-flex align-items-center p-0"
              >
                <i className="bx bx-envelope fs-xl text-primary me-2"></i>
                enquiries@gethelp.ng
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-5 col-md-6 offset-xl-1">
          <div className="card border-0 shadow-sm p-sm-2">
            <form className="card-body needs-validation">
              <div className="mb-4">
                <label htmlFor="name" className="form-label fs-base">
                  Full name
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-control form-control-lg"
                  required
                />
                <div className="invalid-feedback">Please enter your name!</div>
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="form-label fs-base">
                  Email address
                </label>
                <input
                  type="email"
                  id="email"
                  className="form-control form-control-lg"
                  required
                />
                <div className="invalid-feedback">
                  Please provide a valid email address!
                </div>
              </div>
              <div className="mb-4 pb-2">
                <label htmlFor="message" className="form-label fs-base">
                  Email address
                </label>
                <textarea
                  id="message"
                  className="form-control form-control-lg"
                  rows="3"
                  placeholder="Tell us about your project"
                  required
                ></textarea>
                <div className="invalid-feedback">
                  Please enter you message!
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-success w-100 shadow-success btn-lg"
              >
                Send request
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

export default GetInTouch;
