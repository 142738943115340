import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ContactLeftSide from "../components/ContactLeftSide";
import OnlineConsultation from "../components/OnlineConsultation";
import Faqs from "../layouts/Faqs";

const ContactUs = () => (
  <>
    <section className="position-relative bg-secondary pt-5">
      <Container className="container position-relative zindex-2 pt-5">
        <Row className="row justify-content-center pt-5 pb-1 pb-sm-2 pb-md-3">
          <ContactLeftSide />
          <OnlineConsultation />
        </Row>
      </Container>
    </section>

    <Faqs />
  </>
);

export default ContactUs;
